<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { dialogStore, offlineProject, onlineProject, userStore } from '@/main';
import Firebase from '@/modules/firebase/core';
import { Operation } from '@/modules/firebase/operations';
import { wall } from '@/modules/firebase/walls';
import router from '@/router';
import { projectDefaults, RPCResponse } from '@/structures';
import AccountButton from '@/components/AccountButton.vue';
import AnimatedLogo from '@/components/AnimatedLogo.vue';
import BoxModelPanel from '@/components/panels/BoxModelPanel.vue';
import CanvasPanel from '@/components/panels/CanvasPanel.vue';
import DownloadMenu from '@/components/DownloadMenu.vue';
import EditorPanel from '@/components/panels/EditorPanel.vue';
import EditorWizard from '@/components/EditorWizard.vue';
import Icon from '@/components/Icon.vue';
import ProjectLink from '@/components/ProjectLink.vue';
import ProjectSettingsMenu from '@/components/ProjectSettingsMenu.vue';
import Resizer from '@/components/Resizer.vue';
import ShareMenu from '@/components/ShareMenu.vue';
import ToggleInput from '@/components/ToggleInput.vue';

const canvasPanelRatio = { min: 0.2, max: 1.8 };
const editorPanelRatio = { min: 0.2, max: 1.8 };
const projectReference = computed(() => userStore.authenticated ? onlineProject : offlineProject);
const projectSettingsDialog = ref({ show: false, project: '' });
const resizing = ref(false);
const showBoxModel = ref(true);
const showDownload = ref(false);
const showShare = ref(false);
const verticalEditorArrangement = ref(false);
let mobileQuery: MediaQueryList;

async function createNewProject (force = false): Promise<void> {
	let confirmation = true;
	if (!force && Number(projectReference.value.css?.length) > 0) {
		confirmation = window.confirm('You have unsaved changes. Are you sure you want to create a new project?');
	}
	if (!confirmation) return;
	const response = await Operation.create(Operation.Type.Project, {});
	if (response === RPCResponse.SubscriptionRequired().status) {
		wall.premium();
	}
}
function openDownloadMenu (): void {
	wall.message = 'Login to download this project.';
	if (!wall.authenticated()) return;
	showDownload.value = true;
}
function openShareMenu (): void {
	wall.message = 'Login to share this project.';
	if (!wall.multiple([wall.authenticated, wall.verified])) return;
	showShare.value = true;
}
function openProjectSettings (projectId: string): void {
	projectSettingsDialog.value.project = projectId;
	projectSettingsDialog.value.show = true;
}
function updateResizing (value: boolean): void {
	resizing.value = value;
}
function saveProject (): void {
	Firebase.projectBatcher.flush(onlineProject.id);
}
function updateMobileArrangement (query: MediaQueryList | MediaQueryListEvent): void {
	verticalEditorArrangement.value = query.matches;
	if (query.matches) showBoxModel.value = false;
}
function handleKeybinds (event: KeyboardEvent): void {
	// Allow dialogs to be closed with the `Escape` key.
	if (event.code === 'Escape') {
		showDownload.value = false;
		showShare.value = false;
	}
	// Allow the project to be saved with `[Ctrl|Meta]+S`.
	if (event.code === 'KeyS' && (event.ctrlKey || event.metaKey)) {
		event.preventDefault();
		saveProject();
	}
}

watch(() => router.currentRoute.value.params.id, id => {
	if (!userStore.authenticated) {
		router.replace('/create');
		return;
	}
	if (typeof id === 'string') {
		onlineProject.select(id);
	}
});

watch(
	() => onlineProject.id,
	id => {
		if (!id) return;
		if (!router.currentRoute.value.params.id) {
			router.replace(`/create/${onlineProject.id}`);
			return;
		}
		router.push(`/create/${onlineProject.id}`);
	},
	{ immediate: true }
);

watch(
	() => userStore.authenticated,
	authenticated => {
		if (authenticated) return;
		router.replace('/create');
	},
	{ immediate: true }
);

onMounted(() => {
	window.addEventListener('keydown', handleKeybinds);
	// Save the project if the user leaves the window.
	document.addEventListener('mouseleave', saveProject);
	document.addEventListener('visibilitychange', () => { document.visibilityState === 'hidden' && saveProject(); });
	// Arrange the editor vertically if the viewport width is too small.
	mobileQuery = window.matchMedia('only screen and (max-width: 50rem)');
	updateMobileArrangement(mobileQuery);
	mobileQuery.addEventListener('change', updateMobileArrangement);
});

onUnmounted(() => {
	window.removeEventListener('keydown', handleKeybinds);
	mobileQuery.removeEventListener('change', updateMobileArrangement);
});
</script>

<template>
	<main :class="{ showProjectLinks: userStore.authenticated }">
		<nav>
			<RouterLink to="/">
				<AnimatedLogo class="logo" />
			</RouterLink>
			<label for="projectTitle" class="visuallyHidden">Project Title</label>
			<input
				type="text"
				id="projectTitle"
				name="projectTitle"
				class="projectTitle"
				maxlength="50"
				v-model="projectReference.title"
				data-journey-id="CreateProjectTitle"
			>
			<button
				v-if="!userStore.authenticated"
				title="New"
				@click="createNewProject()"
				data-journey-id="CreateNew"
			>
				<Icon>add</Icon>
			</button>
			<button
				title="Download this project"
				@click="openDownloadMenu()"
				data-journey-id="Download"
			>
				<Icon>cloud_download</Icon>
			</button>
			<button
				title="Share this project"
				@click="openShareMenu()"
				data-journey-id="Share"
			>
				<Icon>share</Icon>
			</button>
			<button
				class="saveButton"
				:disabled="!Firebase.projectBatcher.pending.value"
				:title="Firebase.projectBatcher.pending.value ? 'Saving…' : 'Saved'"
				@click="saveProject()"
				data-journey-id="CreateSave"
			>
				<span v-if="Firebase.projectBatcher.pending.value" class="spinner slow" style="width: 1em;height: 1em;"></span>
				<Icon v-show="!Firebase.projectBatcher.pending.value">cloud_done</Icon>
				<span>{{ Firebase.projectBatcher.pending.value ? 'Saving…' : 'Saved' }}</span>
			</button>
			<div class="spacer"></div>
			<button
				title="Help"
				@click="dialogStore.open(dialogStore.Dialog.Help)"
				data-journey-id="CreateHelp"
			>
				<Icon>question_mark</Icon>
			</button>
			<AccountButton round />
		</nav>

		<aside class="projectLinks">
			<div class="projectLinksInner">
				<button
					:disabled="Firebase.loading.value"
					title="New project"
					@click="createNewProject(true)"
					data-journey-id="CreateProjectLinksNew"
				>
					<span v-if="Firebase.loading.value" class="spinner"></span>
					<Icon v-else>add</Icon>
				</button>
				<ul>
					<li
						v-for="[id, project], index of Firebase.projects.value"
						:key="id"
						:style="{ animationDelay: `calc(0.02s * ${index})` }"
					>
						<ProjectLink
							:title="project.title"
							:date="project.dateEdited"
							:loading="Firebase.loading.value"
							:selected="id === onlineProject.id"
							@click.stop="onlineProject.select(id)"
							@settings="openProjectSettings(id)"
						/>
					</li>
				</ul>
			</div>
		</aside>

		<section class="editorPanels">
			<EditorPanel
				:style="{
					width: verticalEditorArrangement ? '100%' : `${50 * projectReference.editorRatio}%`,
					height: verticalEditorArrangement ? `${50 * projectReference.editorRatio}%` : '100%',
					transition: resizing ? '0s ease width, 0s ease height' : '0.4s ease width, 0.4s ease height'
				}"
			/>
			<Resizer
				:horizontal="verticalEditorArrangement"
				:min="editorPanelRatio.min"
				:max="editorPanelRatio.max"
				:default="projectDefaults.editorRatio"
				@resizing="updateResizing"
				v-model="projectReference.editorRatio"
			/>
			<div
				:style="{
					width: verticalEditorArrangement ? '100%' : `${100 - 50 * projectReference.editorRatio}%`,
					height: verticalEditorArrangement ? `${100 - 50 * projectReference.editorRatio}%` : '100%',
					transition: resizing ? '0s ease width, 0s ease height' : '0.4s ease width, 0.4s ease height'
				}"
			>
				<CanvasPanel
					:style="{
						height: showBoxModel ? `${50 * projectReference.canvasRatio}%` : '100%',
						transition: resizing ? '0s ease height' : '0.4s ease height'
					}"
				>
					<template v-slot:settings>
						<label
							for="showBoxModel"
							class="canvasSetting"
							:title="showBoxModel ? 'Hide box model' : 'Show box model'"
						>
							<Icon outline>dialogs</Icon>
							<ToggleInput name="showBoxModel" v-model="showBoxModel" />
						</label>
					</template>
				</CanvasPanel>
				<Resizer
					v-show="showBoxModel"
					horizontal
					:min="canvasPanelRatio.min"
					:max="canvasPanelRatio.max"
					:default="projectDefaults.canvasRatio"
					@resizing="updateResizing"
					v-model="projectReference.canvasRatio"
				/>
				<BoxModelPanel
					:style="{
						height: showBoxModel ? `${100 - 50 * projectReference.canvasRatio}%` : '0%',
						opacity: showBoxModel ? '1' : '0',
						transition: resizing ? '0s ease height, 0s ease opacity' : '0.4s ease height, 0.4s ease opacity'
					}"
				/>
			</div>
		</section>
	</main>

	<ProjectSettingsMenu
		:project="projectSettingsDialog.project"
		v-model="projectSettingsDialog.show"
	/>
	<DownloadMenu
		v-if="onlineProject.id"
		:project="onlineProject.id"
		v-model="showDownload"
	/>
	<ShareMenu
		v-if="onlineProject.id"
		:project="onlineProject.id"
		v-model="showShare"
	/>
	<EditorWizard />
</template>

<style scoped>
main {
	display: grid;
	grid-template-columns: auto 1fr;
	grid-template-rows: 3.25rem calc(100vh - 3.25rem);
	grid-template-rows: 3.25rem calc(100dvh - 3.25rem);
	height: 100vh;
	height: 100dvh;
	background-color: #eee;
	user-select: none;
}
html.dark main {
	background-color: var(--color-background-dark);
}
main > section {
	display: flex;
	flex-flow: row nowrap;
	width: 100%;
	max-width: unset;
	max-height: 100%;
	margin: 0;
	padding: 0;
}

nav {
	grid-area: 1 / 1 / 2 / 3;
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: center;
	gap: 1ch;
	margin: 0.5rem 0.5rem 0.25rem;
	padding: 0.7ch 1ch;
}
nav .logo {
	display: block;
	width: 2rem;
	height: 2rem;
	margin-right: 1ch;
}
nav .projectTitle {
	width: 210px;
	padding: 0.2em 0.75ch;
	font-size: 1rem;
	border-radius: var(--border-radius);
}

nav > .spacer {
	margin-left: auto;
}

.projectLinks {
	display: flex;
	flex-flow: column nowrap;
	width: 0;
	height: 100%;
	padding: 1rem 0.125rem;
	opacity: 0;
	transition: 0.4s ease width, 0.4s ease opacity;
	user-select: none;
	pointer-events: none;
}
main.showProjectLinks .projectLinks {
	width: 17.8rem;
	padding: 0.25rem 0.25rem 0.5rem 0.5rem;
	opacity: 1;
	user-select: revert;
	pointer-events: all;
}
.projectLinks::-webkit-scrollbar {
	width: 0.5em;
	height: 0.5em;
}
.projectLinks::-webkit-scrollbar-track {
	background: var(--color-background);
}
.projectLinks::-webkit-scrollbar-track:horizontal {
	background: var(--color-background);
}
.projectLinks::-webkit-scrollbar-thumb {
	background: var(--color-border-light);
	border-radius: 0.5em;
}
.projectLinks::-webkit-scrollbar-thumb:horizontal {
	background: var(--color-border-light);
	border-radius: 0.5em;
}
.projectLinks::-webkit-scrollbar-thumb:hover {
	background: var(--color-border);
}

.projectLinksInner {
	display: flex;
	flex-flow: column nowrap;
	gap: 0.5rem;
	width: 100%;
	height: 100%;
	padding: 0.5rem 0.5rem 1rem;
	background-color: var(--color-background);
	border-radius: var(--border-radius-large);
	overflow-y: auto;
}
.projectLinksInner::before {
	content: '';
	position: absolute;
	inset: 0;
	border-radius: inherit;
	background: radial-gradient(circle at 50% 0, #fff, transparent);
	opacity: 0.02;
	pointer-events: none;
}

.projectLinks ul {
	z-index: 1;
	display: flex;
	flex-flow: column nowrap;
	gap: 0.5rem;
	width: 100%;
	margin: 0;
	padding: 0;
	list-style: none;
}
.projectLinks ul > li {
	display: flex;
	opacity: 0;
	transform: translateX(-30px);
	animation: projectLinkTransitionIn 0.15s ease forwards;
}

@keyframes projectLinkTransitionIn {
	0%   { opacity: 0; transform: translateX(-30px); }
	100% { opacity: 1; transform: translateX(0); }
}

.editorPanels {
	overflow: hidden;
}

.canvasSetting {
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: center;
	gap: 0.5ch;
	height: 100%;
	padding: 0.25em;
	font-size: 0.8rem;
	background-color: var(--color-border-light);
	border-radius: var(--border-radius);
	cursor: pointer;
	
	& > span {
		font-size: 1.25rem;
	}

	&:has(:focus-visible) {
		outline: auto;
	}
}

@media only screen and (max-width: 80rem) {
	main {
		grid-template-columns: 1fr;
		grid-template-rows: 3.25rem auto calc(100vh - 3.25rem);
		grid-template-rows: 3.25rem auto calc(100dvh - 3.25rem);
	}
	main.showProjectLinks {
		grid-template-columns: 1fr;
		grid-template-rows: 3.25rem auto calc(100vh - 3.25rem - 4.5rem);
		grid-template-rows: 3.25rem auto calc(100dvh - 3.25rem - 4.5rem);
	}
	nav {
		grid-area: 1 / 1 / 2 / 2;
	}
	.projectLinks {
		width: 100%;
		height: 0;
		padding: 0 0.5rem;
		transition: 0.4s ease height, 0.4s ease opacity;
		overflow: hidden;
	}
	main.showProjectLinks .projectLinks {
		width: 100%;
		height: 4.5rem;
		padding: 0.25rem 0.5rem;
	}
	.projectLinksInner {
		flex-flow: row nowrap;
		padding: 0.5rem;
		overflow-x: auto;
		overflow-y: hidden;
	}
	.projectLinks ul {
		flex-flow: row nowrap;
	}
	.projectLink {
		grid-template-columns: 12ch min-content;
	}
	.editorContainer {
		padding-left: 0.5rem;
	}
}

@media only screen and (max-width: 50rem) {
	nav {
		margin: 0;
	}
	nav .projectTitle {
		max-width: 20ch;
	}
	main.showProjectLinks .projectLinks {
		padding: 0.25rem;
		padding-top: 0;
	}
	.editorPanels {
		flex-flow: column nowrap;
		height: 100%;
	}
	.editorContainer {
		padding: 0.25rem;
	}
	.canvasPanel {
		padding: 0.25rem;
	}
}

@media only screen and (max-width: 550px) {
	nav .projectTitle {
		max-width: 15ch;
	}
	.saveButton > span:last-of-type {
		display: none;
	}
}

@media only screen and (max-width: 28rem) {
	nav .projectTitle {
		display: none;
	}
}
</style>

<style>
.v-enter-active,
.v-leave-active {
	transition: 0.2s ease opacity;
}

.v-enter-active {
	position: absolute;
}

.v-enter-from,
.v-leave-to {
	opacity: 0;
}
</style>
